/** @format */

import React from "react";
import Carousel from "react-bootstrap/Carousel";

const ImageSlider = (props) => {
  const content = props.pdata["data"];
  let carouSelItem = null;
  let finalContent = content.filter((item1) => {
    return item1["url"].length > 0;
  });
  let carouSelItems = finalContent.map((item, i) => {
    const imgUrl = item["url"];

    carouSelItem = (
      <img
        className="d-block w-100"
        src={imgUrl}
        alt="..."
        id={"img" + i}
        key={"imgk" + i}
      />
    );
    return (
      <Carousel.Item className="carousel-item" id={"car" + i} key={"cark" + i}>
        {carouSelItem}
      </Carousel.Item>
    );
  });
  return (
    <div className="p-2 px-sm-3 py-sm-1 ">
      <div
        id="carouselImg"
        className="carousel carousel-dark slide my-1"
        data-bs-ride="carousel"
        key="carouselKey"
      >
        <Carousel className="carousel-inner" id="carInn" key="carInnKey">
          {carouSelItems}
        </Carousel>
      </div>
    </div>
  );
};
export default ImageSlider;
